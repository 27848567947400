.app {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Futura, "Trebuchet MS", Arial, sans-serif;
}

.jumbotron {
  width: 100vw;
  height: 250px;
  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.65),
      rgba(255, 255, 255, 0.65)
    ),
    url(/static/media/cover.e7a82e5b.png);
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.jumbotron h1 {
  font-size: 45px;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0 0 0 0;
}

.jumbotron h2 {
  font-size: 20px;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 10px;
  margin: 0 0 0 0;
}

/* Navbar */

.navbar {
  background: linear-gradient(
    90deg,
    hsl(202, 65%, 58%) 0%,
    rgba(73, 63, 252, 1) 100%
  );
  margin: 0 0 0 0;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  width: 100vw;
  position: sticky;
  top: 0;
}
.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.nav-link {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-link:hover {
  background-color: #6d76f7;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.introduction {
  padding-top: 50px;
  width: 100vw;
  display: grid;
  grid-template-columns: [start] 10% [first] 50% [middle1] auto [middle2] 25% [last] 10% [end];
  align-items: center;
}

.intro-text {
  grid-column-start: first;
  grid-column-end: middle1;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.intro-pic {
  grid-column-start: middle2;
  grid-column-end: last;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.try-list {
  list-style: square;
  list-style-position: inside;
}

.experience {
  text-align: center;
  margin: 0 0 0 0;
  padding-top: 50px;
  justify-content: center;
}

.card-container {
  width: 300px;
  height: 550px;
  overflow: hidden;
  box-shadow: 0px 0px 15px -5px;
  transition: 0.3s;
  -webkit-animation: ease-in-out;
          animation: ease-in-out;
  margin: 15px 15px 15px 15px;
  position: relative;
}

.card-container:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  box-shadow: 0px 0px 15px 0px;
}

.image-container img {
  overflow: hidden;
  width: 300px;
  height: 150px;
}

.card-content {
  margin: 1rem;
  margin-top: 0.5rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-button {
  display: flex;
  justify-content: center;
}

.card-button button {
  padding: 0.5rem;
  background-color: white;
  border: none;
  transition: 0.2s;
  margin-bottom: 0.5rem;
  border-radius: 3px;
}

.card-button button:hover {
  background: rgba(27, 156, 252, 0.1);
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.card-button a {
  text-transform: uppercase;
  color: #1b9cfc;
  text-decoration: none;
  font-weight: bold;
}

.card-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.card-footer {
  width: 300px;
  height: 60px;
  background-color: #f5f4f5;
  position: absolute;
  bottom: 0px;
  text-align: center;
  justify-content: center;
}

.coursework {
  text-align: center;
  margin: 0 0 0 0;
  padding-top: 50px;
  justify-content: center;
}

.classcard-container {
  width: 375px;
  height: 325px;
  overflow: hidden;
  box-shadow: 0px 0px 15px -5px;
  transition: 0.3s;
  -webkit-animation: ease-in-out;
          animation: ease-in-out;
  margin: 15px 15px 15px 15px;
  position: relative;
}

.classcard-container:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  box-shadow: 0px 0px 15px 0px;
}

.classcard-header {
  overflow: hidden;
  background-color: #f5f4f5;
  width: 400px;
  height: 50px;
  position: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.classcard-content {
  margin: 1rem;
  margin-top: 0.5rem;
}

.course-line {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 0 0;
  margin: 0 0 0 0;
}

.badge {
  color: white;
  font-weight: bold;
  background-color: #4ea5d9;
  border-radius: 0.5em;
  border-style: solid;
  border-width: 0.063em;
  height: 15px;
  padding: 0.2em;
  display: inline-block;
  margin-left: 10px;
  font-size: 0.8em;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.project {
  text-align: center;
  margin: 0 0 0 0;
  justify-content: center;
  padding: 50px 0 50px 0;
}

.footer {
  width: 100vw;
  height: 75px;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 50px 0 50px 0;
  background-color: #f5f4f5;
}

.social-icons {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.icon {
  width: 28px;
  height: 28px;
  margin: 0px 10px 0px 10px;
  transition: ease transform 200ms, ease-in color 100ms;
}

.icon:hover {
  cursor: pointer;
  color: #4ea5d9;
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}

